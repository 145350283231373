import "./App.css";
import logo from "./Reach_google.png";
import section1Image from "./withinreach-section1.jpg";
import section2Image from "./clients.png";
import section3Image from "./approach.png";
import section4Image from "./mantri-cards.jpg";
import section5Image from "./besides.jpg";
function App() {
    return (
        <div className="App">
            <div className="header">
                <div className="appBarLogo">
                    <img prop="" className="logoImg" src={logo} />
                </div>
                <div className="appBarLogoWrapper">
                    <div className="appBarSocial">
                        <a href="https://twitter.com/withinreach_in" target="_blank">
                            <svg enableBackground="new 0 0 32 32" version="1.1" viewBox="0 0 32 32">
                                <g id="Flat_copy">
                                    <g>
                                        <path d="M16,0C7.163,0,0,7.163,0,16c0,8.836,7.163,16,16,16s16-7.164,16-16C32,7.163,24.837,0,16,0z" fill="#333333" />
                                    </g>
                                    <path
                                        d="M18.226,8.886c-1.59,0.579-2.595,2.071-2.481,3.704l0.038,0.63l-0.636-0.077   c-2.315-0.296-4.338-1.299-6.056-2.984l-0.84-0.836L8.036,9.94c-0.458,1.376-0.165,2.83,0.789,3.808   c0.509,0.54,0.394,0.617-0.483,0.296c-0.305-0.103-0.573-0.18-0.598-0.141c-0.089,0.09,0.216,1.26,0.458,1.724   c0.331,0.644,1.005,1.273,1.743,1.647l0.624,0.296L9.83,17.581c-0.712,0-0.738,0.013-0.661,0.284   c0.254,0.836,1.259,1.724,2.379,2.11l0.789,0.27l-0.687,0.412c-1.018,0.593-2.214,0.927-3.41,0.951   c-0.573,0.013-1.044,0.064-1.044,0.103c0,0.128,1.553,0.848,2.455,1.132c2.71,0.836,5.929,0.475,8.346-0.952   c1.718-1.016,3.435-3.036,4.237-4.992c0.433-1.041,0.865-2.945,0.865-3.858c0-0.592,0.038-0.669,0.75-1.376   c0.42-0.412,0.814-0.862,0.891-0.99c0.128-0.245,0.114-0.245-0.534-0.026c-1.081,0.386-1.234,0.335-0.699-0.244   c0.394-0.412,0.865-1.158,0.865-1.376c0-0.038-0.191,0.026-0.407,0.141c-0.229,0.129-0.738,0.322-1.12,0.437l-0.687,0.219   L21.535,9.4c-0.344-0.231-0.826-0.489-1.081-0.566C19.804,8.654,18.812,8.68,18.226,8.886z"
                                        fill="#FFFFFF"
                                    />
                                </g>
                            </svg>
                        </a>
                    </div>
                    <div className="appBarSocial">
                        <a href="https://facebook.com/withinreach.in" target="_blank">
                            <svg enableBackground="new 0 0 32 32" version="1.1" viewBox="0 0 32 32">
                                <g id="Flat_copy">
                                    <g>
                                        <path d="M16,0C7.163,0,0,7.163,0,16c0,8.836,7.163,16,16,16s16-7.164,16-16C32,7.163,24.837,0,16,0z" fill="#333333" />
                                    </g>
                                    <path
                                        d="M13.69,24.903h3.679v-8.904h2.454l0.325-3.068h-2.779l0.004-1.536c0-0.8,0.076-1.229,1.224-1.229h1.534   V7.097h-2.455c-2.949,0-3.986,1.489-3.986,3.992v1.842h-1.838v3.069h1.838V24.903z"
                                        fill="#FFFFFF"
                                    />
                                </g>
                            </svg>
                        </a>
                    </div>
                    <div className="appBarSocial">
                        <a href="https://instagram.com/withinreach.in" target="_blank">
                            <svg enableBackground="new 0 0 32 32" version="1.1" viewBox="0 0 32 32">
                                <g id="Flat_copy">
                                    <g>
                                        <path d="M16,0C7.163,0,0,7.163,0,16c0,8.836,7.163,16,16,16s16-7.164,16-16C32,7.163,24.837,0,16,0z" fill="#333333" />
                                    </g>
                                    <path
                                        d="M22.057,7.93H9.943c-1.14,0-2.019,0.879-2.019,2.019v12.113c0,1.14,0.879,2.019,2.019,2.019h12.113   c1.14,0,2.019-0.879,2.019-2.019V9.949C24.076,8.808,23.196,7.93,22.057,7.93z M16.012,12.833c1.791,0,3.243,1.407,3.243,3.142   c0,1.735-1.452,3.142-3.243,3.142c-1.79,0-3.242-1.408-3.242-3.142C12.77,14.239,14.222,12.833,16.012,12.833z M22.057,21.557   c0,0.354-0.151,0.505-0.505,0.505H10.448c-0.353,0-0.505-0.151-0.505-0.505v-7.066l1.258,0.274   c-0.135,0.439-0.208,0.903-0.208,1.385c0,2.684,2.248,4.863,5.018,4.863c2.772,0,5.019-2.178,5.019-4.863   c0-0.482-0.073-0.946-0.208-1.385l1.234-0.274V21.557z M22.057,12.472c0,0.279-0.226,0.505-0.505,0.505h-2.019   c-0.279,0-0.505-0.226-0.505-0.505v-2.019c0-0.279,0.226-0.505,0.505-0.505h2.019c0.279,0,0.505,0.226,0.505,0.505V12.472z"
                                        fill="#FFFFFF"
                                    />
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <div className="section1">
                <div className="sec1textWrap">
                    <h1 className="sec1text">We support NGOs and company foundations (CSR) in designing and implementing their programmes, keeping data at the fore.</h1>
                </div>
                <div className="section1banner">
                    {" "}
                    <div className="section1bannerImage">
                        <img prop="" src={section1Image} />
                    </div>
                    <div className="name1Card">
                        <div className="name1Title">
                            <p>Services</p>
                        </div>
                        <div className="name1Text">
                            We help you collect data conveniently and consume it intelligently, taking your reporting and decision making to the next level.
                            <span className="italic"> The right one.</span>
                        </div>
                    </div>{" "}
                    <div className="='section1bannerTest"></div>
                </div>
                <div className="section2banner">
                    <div className="section2bannerImage">
                        <img prop="" src={section2Image} />
                    </div>
                </div>
                <div className="section3banner">
                    <div className="section3bannerImage">
                        <img prop="" src={section3Image} />
                    </div>
                </div>
                <div className="sec1textWrap">
                    <h1 className="sec1text">We're also interested in building awareness on social issues, with the community at large.</h1>
                </div>
                <div className="section1banner">
                    <div className="section1bannerImage pointer" onClick={() => window.open("https://mantricards.in", "_blank")}>
                        <img prop="" src={section4Image} />
                    </div>
                    <div className="name1Card pointer" onClick={() => window.open("https://mantricards.in", "_blank")}>
                        <div className="name1Title">
                            <p>Mantri Cards</p>
                        </div>
                        <div className="name1Text">
                            <span className="boldText">Mantri Cards™</span> is a top trump style card game made in India. With information of 100 Members of Parliament{" "}
                            <span className="boldText">(MPs) from the 17th Lok Sabha</span>, and 8 unique Power Cards to alter game play, this game comes in a durably built box that you can carry with
                            you wherever you go
                        </div>
                    </div>{" "}
                    <div className="='section1bannerTest">We're also interested in building awareness on social issues, with the community at large</div>
                </div>
                <div className="section1banner ">
                    {" "}
                    <div className="section1bannerImage pointer" onClick={() => window.open("https://besides.in", "_blank")}>
                        <img prop="" src={section5Image} />
                    </div>
                    <div className="name1Card pointer" onClick={() => window.open("https://besides.in", "_blank")}>
                        <div className="name1Title">
                            <p>Besides</p>
                        </div>
                        <div className="name1Text">
                            We developed a prototype web app that make dense subjects more digestible... and more fun! Whether it's information on government policies, global indices, or even locl
                            politicians, we've got you covered.
                        </div>
                    </div>{" "}
                    \
                </div>
                <div className="sessioncontactbanner">
                    <div className="contactTitle">CONTACT US</div>
                    <div className="contactEmail">
                        Email:{" "}
                        <div className="emailID">
                            <a href="mailto:whats@withinreach.in">whats@withinreach.in</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
